import { createApp } from "/node_modules/.vite/deps/vue.js?v=0b561efa"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=0e7e3a0a"

// API
import { getAllData } from "/src/api/api.js"

import App from "/src/App.vue"
import router from "/src/router/index.js"

// i18n语言包
import { createI18n } from "/node_modules/.vite/deps/vue-i18n.js?v=be8a5032"

import "/node_modules/swiper/swiper.css"
import "/node_modules/swiper/modules/navigation.css"
import "/node_modules/swiper/modules/pagination.css"

// 引入element-plus
import ElementPlus from "/node_modules/.vite/deps/element-plus.js?v=1daae245"
import * as ElementPlusIconsVue from "/node_modules/.vite/deps/@element-plus_icons-vue.js?v=65e14a1e"
import "/node_modules/element-plus/dist/index.css"

// 引入全局样式修改element-plus样式
import "/src/assets/css/elemenGlobal.css"
// 引入全局样式
import "/src/assets/css/ProjectGlobal.scss"

// import zhData from '@/locales/zh.json'
// import enData from '@/locales/en.json'
// import deData from '@/locales/de.json'
// import globalJson from '../src/globalData.json'
// 创建一个异步函数来加载语言包
// 创建一个异步函数来加载语言包数据
const loadLocaleData = async () => {
  const languages = ['zh', 'en', 'de']
  const localeData = {}

  for (const lang of languages) {
    const sessionData = sessionStorage.getItem(`localeData_${lang}`)

    if (sessionData) {
      localeData[lang] = JSON.parse(sessionData)
    } else {
      const response = await getAllData({ path: lang }, lang)
      const data = JSON.parse(response.data.data.metaData.text)
      localeData[lang] = data
      sessionStorage.setItem(`localeData_${lang}`, JSON.stringify(data))
    }
  }

  return localeData
}

// 创建一个异步函数来初始化应用
async function initApp() {
  // 加载语言包数据
  const { zh, en, de } = await loadLocaleData()

  // 创建 i18n 实例
  const i18n = createI18n({
    locale: 'de', // 默认语言
    messages: {
      zh: zh,
      en: en,
      de: de
    }
  })

  const app = createApp(App)

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  app.use(createPinia())
  app.use(router)
  app.use(ElementPlus)
  app.use(i18n)

  let globalJson
  if (sessionStorage.getItem('globalData')) {
    globalJson = JSON.parse(sessionStorage.getItem('globalData'))
  } else {
    const globalData = await getAllData(
      {
        path: 'global'
      },
      'zh'
    )
    globalJson = JSON.parse(globalData.data.data.metaData.text)
    sessionStorage.setItem('globalData', JSON.stringify(globalJson))
  }
  app.config.globalProperties.$data = globalJson

  app.mount('#app')
}

// 调用初始化函数
initApp().catch((error) => {
  console.error('Error initializing app:', error)
})
